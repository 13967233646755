<template>
  <ListPage
    :gridData="filteredData"
    :gridConfig="gridConfig"
    :filters="filters"
    v-if="allCustomers"
  />
</template>

<script>
import { mapGetters } from "vuex";
import ListPage from "@/components/penal-retail-helpers/ListPage";

import countries from "@/data/generic/countries.json";
import { countryStates } from "@/helpers/utils";

let collectionName = "customers";

export default {
  components: {
    ListPage,
  },
  computed: {
    ...mapGetters(["allCustomers"]),
    filteredData() {
      let filteredData = this.allCustomers;

      ["state"].forEach((a) => {
        if (this.appliedFilters[a]) {
          filteredData = filteredData.filter(
            (x) => !!x[a] && x[a].id === this.appliedFilters[a].id
          );
        }
      });

      //Caso especial de país, donde filtramos por las provincias de ese pais
      ["country"].forEach((a) => {
        if (this.appliedFilters[a]) {
          filteredData = filteredData.filter(
            (x) =>
              !!x.state &&
              countryStates(this.appliedFilters[a].id)
                .map((s) => s.id)
                .includes(x.state.id)
          );
        }
      });

      return filteredData;
    },
    filters() {
      let filters = [];
      filters.push({
        size: "1/5",
        mobileSize: "1/2",
        extraClasses: "mb-5",
        search: true,
      });

      filters.push({
        size: "1/5",
        mobileSize: "1/2",
        enum: true,
        enumType: "countries",
        placeholder: `${collectionName}.placeholders.country`,
        label: "alias",
        clearable: true,
        onChange: (e) => {
          this.updateFilters("country", e);
        },
        options: () => countries,
        value: this.appliedFilters.country,
      });
      filters.push({
        size: "1/5",
        mobileSize: "1/2",
        select: true,
        placeholder: `${collectionName}.placeholders.state`,
        label: "alias",
        clearable: true,
        onChange: (e) => {
          this.updateFilters("state", e);
        },
        disabled: !this.appliedFilters.country,
        options: () =>
          this.appliedFilters.country
            ? countryStates(this.appliedFilters.country.id)
            : [],
      });

      filters.push({
        size: "2/5",
        mobileSize: "1/2",
        button: true,
        i18n: `${collectionName}.buttons.new`,
        extraClasses: "text-right",
        to: { name: "settings-customers-new" },
      });

      return filters;
    },
  },
  data() {
    let columns = [
      {
        headerName: this.$t(`${collectionName}.columns.code`),
        filter: false,
        width: 75,
        field: "code",
      },
      {
        headerName: this.$t(`${collectionName}.columns.alias`),
        field: "alias",
        filter: false,
        width: 150,
      },
      {
        headerName: this.$t(`${collectionName}.columns.bussinessName`),
        field: "bussinessName",
        filter: false,
        width: 100,
      },
      {
        headerName: this.$t(`${collectionName}.columns.state`),
        field: "state",
        filter: false,
        width: 150,
        valueGetter: (params) => {
          return params.data.state ? params.data.state.alias : null;
        },
      },
    ];

    return {
      appliedFilters: {
        customer: null,
        country: null,
        state: null,
      },
      gridConfig: {
        components: {},
        gridOptions: {},
        columnDefs: columns,
        defaultColDef: {
          sortable: true,
          resizable: true,
        },
        onRowClicked: this.onRowClicked,
      },
    };
  },
  methods: {
    updateFilters(key, value) {
      if (this.appliedFilters[key] !== value) {
        this.appliedFilters[key] = value;
      }
    },
    onRowClicked(params) {
      this.$router
        .push(`/settings/${collectionName}/${params.data.id}`)
        .catch(() => {});
    },
  },
};
</script>
